import React, { useEffect, useState, Fragment } from "react"
import "./react-modal.css"
import { Modal } from "react-responsive-modal"
import axios from "axios"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerTop from "../components/bannerTop"
import FeaturedProperties from "../components/properties/FeaturedProperties"
import FilterProperties from "../components/properties/FilterProperties"
import ContactForm from "../components/forms/contact"

import img1 from "./../images/Fotos Ejecutivos/JoseCardenas.jpg"
import img2 from "./../images/Fotos Ejecutivos/Isabel Lecumberri.jpg"
import img3 from "./../images/Fotos Ejecutivos/Jose Antonio Cárdenas.jpg"
import img4 from "./../images/Fotos Ejecutivos/Erica Larrucea.jpg"
import img5 from "./../images/Fotos Ejecutivos/AliciaRios.png"
import img6 from "./../images/Fotos Ejecutivos/Pilar Guillou.jpg"
import img7 from "./../images/Fotos Ejecutivos/Sara Moya_.jpg"
import img8 from "./../images/Fotos Ejecutivos/no-agent.png"
import img9 from "./../images/Fotos Ejecutivos/Manuel Bustos.jpg"
import img10 from "./../images/Fotos Ejecutivos/Carlos Rojas.jpg"
import img11 from "./../images/Fotos Ejecutivos/MichelleVergara.jpeg"

const Equipo = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [agentes, setAgentes] = useState([])
  const [pathname, setPathname] = useState("")
  const [state, setState] = useState({
    open: false,
    nombre: "",
    correo: "",
  })

  const onOpenModal = (email, nombre) => {}

  const onCloseModal = () => {
    setState({ open: false, nombre: "", correo: "" })
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(false)
      try {
        await axios
          .get(
            `https://clasipro.cl/api/agentes/?token=yH82QGko0poQLHOUJKquRduntueyweg3QhTWw0EZ8ugInGIaeZ`
          )
          .then(r => {
            setAgentes(r.data.agentes)
          })
      } catch (e) {
        console.log("eror")
      }
      setLoading(false)
    }
    fetchData()
    console.log(agentes)
  }, [])

  const plantaAdminData = [
    {
      avatar: img1,
      nombre: "José Miguel Cárdenas Oyarzún ",
      cargo: "Director Ejecutivo",
      email: "jose.cardenas@agenteinmobiliario.cl",
      phone: "982943074",
    },
    //{
    //  avatar: img11,
    //  nombre: "Michelle Vergara Armijo",
    //  cargo: "Gerente Comercial",
    //  email: "michelle.vergara@agenteinmobiliario.cl",
    //  phone: "956835015",
    //},
    {
      avatar: img7,
      nombre: "Sara Moya Álvarez ",
      cargo: "Responsable de Administración",
      email: "administracion@agenteinmobiliario.cl",
      phone: "232249798",
    },
    //{
    //  avatar: img10,
    //  nombre: "Carlos Alberto Rojas Lazo",
    //  cargo: "Encargado de Comunicación y Marketing Digital",
    //  email: "operaciones@agenteinmobilario.cl",
    //  phone: "933807662 - 232249797",
    //},
  ]
  const agentsData = [
    {
      avatar: img2,
      nombre: "Isabel Margarita Lecumberri Bilbao",
      cargo: "Agente Senior Inmobiliario",
      email: "isabel.lecumberri@agenteinmobiliario.cl",
      /*   phone: "+56 998252739", */
    },
    {
      avatar: img3,
      nombre: "José Antonio Cárdenas Oyarzún",
      cargo: "Agente Senior Inmobiliario",
      email: "joseantonio@agenteinmobiliario.cl",
      /*  phone: "+56 987697950", */
    },
    {
      avatar: img4,
      nombre: "Erica Patricia Larrucea Gonzáles ",
      cargo: "Agente Senior Inmobiliario",
      email: "erica.larrucea@agenteinmobiliario.cl",
      /*  phone: "+56 942548727", */
    },
    {
      avatar: img5,
      nombre: "Alicia Elena Ríos Aguirre ",
      cargo: "Agente Senior Inmobiliario",
      email: "alicia.rios@agenteinmobiliario.cl",
      /*     phone: "+56 956602609", */
    },
    {
      avatar: img6,
      nombre: "Pilar Guillou Silva",
      cargo: "Agente Senior Inmobiliario",
      email: "pilar.guillou@agenteinmobiliario.cl",
      /*  phone: "+56 982250398", */
    },

    {
      avatar: img8,
      nombre: "Jorge Curbelo De Santiago",
      cargo: "Agente Inmobiliario",
      email: "jorge.curbelo@agenteinmobiliario.cl",
      /*  phone: "+56 9 9824 1697    ", */
    },
    {
      avatar: img8,
      nombre: "Maria Paz Riveros R.",
      cargo: "Agente Inmobiliario",
      email: "mariapaz.riveros@agenteinmobiliario.cl",
      /*   phone: "+59 935636083  ", */
    },

    {
      avatar: img9,
      nombre: "Manuel Bustos O.",
      cargo: "Agente Inmobiliario",
      email: "manuel.bustos@agenteinmobilario.cl",
      /*   phone: "+56 944117397 ", */
    },
  ]

  useEffect(() => {
    setPathname(window.location.href)
  }, [])

  return (
    <Layout>
      <SEO title="Nuestros ejecutivos" pathname={pathname} />
      <BannerTop title="Ejecutivos" />

      <div className="container contents lisitng-grid-layout">
        <div className="row">
          <div className="span9 main-wrap">
            <div className="main">
              <section className="listing-layout">
                <h3 className="title-heading">Planta Ejecutiva</h3>
                <div className="list-container">
                  {plantaAdminData.map(a => (
                    <article className="about-agent clearfix">
                      <h4>
                        <a href="#">{a.nombre}</a>
                      </h4>

                      <figure>
                        <a href="#">
                          <img
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              objectPosition: "top",
                              maxHeight: "180px",
                              border: "none"
                            }}
                            src={a.avatar}
                          />
                        </a>
                      </figure>

                      <div className="detail">
                        <h6>{a.cargo}</h6>
                        <p>{a.email}</p>

                        <p className="contact-types">
                          {a.phone && (
                            <em>
                              <i class="icon-phone"></i> {a.phone}
                            </em>
                          )}
                        </p>
                        {/* <hr />
                        <button
                          className="btn-blue btn-mini"
                          onClick={() =>
                            setState({
                              open: true,
                              nombre: a.nombre,
                              correo: a.email,
                            })
                          }
                        >
                          Contactar
                        </button> */}
                      </div>
                    </article>
                  ))}
                </div>
              </section>
            </div>

            <div className="main">
              {/* <section className="listing-layout">
                <h3 className="title-heading">Agentes Inmobiliarios</h3>
                <div className="list-container">
                  {agentsData.map(a => (
                    <article className="about-agent clearfix">
                      <h4>
                        <a href="#">{a.nombre}</a>
                      </h4>

                      <figure>
                        <a href="#">
                          <img
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              objectPosition: "top",
                              maxHeight: "180px",
                            }}
                            src={a.avatar}
                          />
                        </a>
                      </figure>

                      <div className="detail">
                        <h6>{a.cargo}</h6>
                        <p>{a.email}</p>

                        <p className="contact-types">
                          {a.phone && (
                            <em>
                              <i class="icon-phone"></i> {a.phone}
                            </em>
                          )}
                        </p>
                         <hr />
                        <button
                          className="btn-blue btn-mini"
                          onClick={() =>
                            setState({
                              open: true,
                              nombre: a.nombre,
                              correo: a.email,
                            })
                          }
                        >
                          Contactar
                        </button> 
                      </div>
                    </article>
                  ))}
                </div>
              </section> */}
            </div>
          </div>
          <div className="span3 sidebar-wrap">
            <aside className="sidebar">
              <section className="widget advance-search">
                <h4 className="title search-heading">
                  Buscar Propiedad<i className="icon-search"></i>
                </h4>
                <div className="as-form-wrap">
                  <FilterProperties />
                </div>
              </section>
              <FeaturedProperties quantity={3} />
            </aside>
          </div>
        </div>
        <div>
          <Modal open={state.open} onClose={onCloseModal} center>
            <h4 style={{ padding: "20px 5px" }}>
              Envíale un mensaje a {state.open ? state.nombre : null}
            </h4>
            <ContactForm />
          </Modal>
        </div>
      </div>
    </Layout>
  )
}

export default Equipo
