import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import "./index.css"

const defaultFormContact = {
  nombre: "",
  correo: "",
  celular: "",
  asunto: "Te contactaron de agenteinmobiliario.cl",
  mensaje: "",
  loading: false,
  request: false,
  requestMessage: "",
}

const ContactForm = ({ location }) => {
  const [form, setForm] = useState(defaultFormContact)
  const [dataMsj, setDataMsj] = useState({
    code: null,
    nameAgent: "",
    emailAgent: "",
  })
  const onChangeInput = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const requestForm = async () => {
    setForm({ ...form, loading: true })
    var formdata = new FormData()
    formdata.append("name", form.nombre)
    formdata.append("email", form.correo)
    formdata.append("mobile", form.celular)
    formdata.append("subject", form.asunto)
    formdata.append(
      "message",
      dataMsj.code
        ? `LINK DE LA PROPIEDAD: <a href="https://www.agenteinmobiliario.cl/propiedades/?code=${dataMsj.code}">presione aquí</a><br/>PROPIEDAD CÓDIGO: ${dataMsj.code}<br/><br/>${form.mensaje}<br/>`
        : `${form.mensaje}`
    )
    formdata.append("nameAgent", dataMsj.nameAgent)
    formdata.append("emailAgent", dataMsj.emailAgent)

    setForm({ loading: true })
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(
      "https://www.agenteinmobiliario.cl/mailApi/sendmail.php",
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        handleFormReset()
        setForm({
          ...defaultFormContact,
          loading: false,
          requestMessage: (
            <p style={{ color: "green" }}>
              Muchas gracias, su consulta ha sido enviada con éxito y será
              respondida por uno de nuestros agentes en las siguientes 4 horas
              hábiles.
            </p>
          ),
        })
      })
      .catch(error =>
        setForm({
          ...form,
          loading: false,
          requestMessage: (
            <p style={{ color: "red" }}>Error intenta nuevamente!</p>
          ),
        })
      )
  }
  const onSubmit = e => {
    e.preventDefault()
    requestForm()
  }

  const handleFormReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    )

    Array.from(document.querySelectorAll("textarea")).forEach(
      textarea => (textarea.value = "")
    )
  }

  useEffect(() => {
    let search = location.search
    let params = new URLSearchParams(search)
    setDataMsj({
      code: params.get("id") ? params.get("id").replace("AGE", "") : "",
      nameAgent: params.get("nameAgent"),
      emailAgent: params.get("eAgent"),
    })
  }, [])

  return (
    <>
      {dataMsj.code && dataMsj.code !== null && (
        <h3 className="title-heading">Consulta propiedad Nº {dataMsj.code}</h3>
      )}
      <form className="contact-form" onSubmit={event => onSubmit(event)}>
        <p>
          <label for="name">Nombre</label>
          <input
            type="text"
            name="nombre"
            onChange={event => onChangeInput(event)}
            title="* Porfavor ingrese su nombre"
            required
          />
        </p>

        <p>
          <label for="email">Email</label>
          <input
            type="email"
            name="correo"
            title="* Porfavor ingrese su correo"
            style={{
              width: "40.4%",
              height: "24px",
              borderRadius: "0%",
              marginBottom: "0px",
            }}
            onChange={event => onChangeInput(event)}
            required
          />
        </p>

        <p>
          <label for="email">Celular</label>
          <input
            type="text"
            minLength="9"
            name="celular"
            title="* Porfavor ingrese su celular"
            onChange={event => onChangeInput(event)}
            required
          />
        </p>

        <p>
          <label for="comment">Mensaje</label>
          <textarea
            style={{ minHeight: "100px" }}
            /* value={
              dataMsj.code &&
              `Hola! Necesito que me contacten por la propiedad CODIGO: ${dataMsj.code}. Muchas Gracias`
            } */
            name="mensaje"
            required
            onChange={event => onChangeInput(event)}
            style={{ width: " 44.4%", height: "44.4%" }}
          ></textarea>
        </p>

        <input
          type="hidden"
          name="subject"
          value="Contacto desde la web de AgenteInmobiliario"
        />
        <div style={{ display: "flex" }}>
          {" "}
          <input
            disabled={form.loading}
            type="submit"
            value={form.loading ? "Enviando" : "Enviar mensaje"}
            id="submit"
            className="real-btn"
            name="submit"
          />
          <div
            style={{
              padding: "5px 20px",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            {" "}
            {form.requestMessage !== "" && !form.loading && form.requestMessage}
          </div>
        </div>
      </form>
    </>
  )
}

export default ContactForm
